import React from 'react'
import './styles.scss'

const Text = {
  Header: function Header({ children, size, weight, color, span, className, ...props }) {

    const style = {
      fontWeight: weight || 'bold',
      color: color || 'var(--text-color)',
    }
    return span ? (
      <span className={`header ${className || ''} ${size || 'normal'}`} style={style} {...props}>
        {children}
      </span>
    ) : (
        <div className={`header ${className || ''} ${size || 'normal'}`} style={style} {...props}>
          {children}
        </div>
      )
  },
  Normal: function Normal({ children, span, size, weight, color, className, ...props }) {
    const style = {
      fontWeight: weight || 'normal',
      color: color || 'var(--text-color)',
    }
    return span ? (
      <span className={`text ${size || 'normal'} ${className || ''}`} style={style} {...props}>
        {children}
      </span>
    ) : (
        <div className={`text ${size || 'normal'} ${className || ''}`} style={style} {...props}>
          {children}
        </div>
      )
  },
}

export default Text
