import React from "react"
import { Link } from "gatsby"
import "./styles.scss"
import Text from "../Text"

const Footer = () => {
  return (
    <footer>
      <div className="content w-75 m-auto row">
        <div className="col">
          <Link to="/">
            <img
              data-testid="eac-logo"
              src="https://res.cloudinary.com/dthskrjhy/image/upload/v1602511555/Emerging%20Artists%20Chicago/logo/EAC_Logo_no_background.png"
              alt="Emerging Artists Chicago Logo"
            />
          </Link>
        </div>
        <div className="footer-links col d-flex flex-column">
          <Link data-testid="home-link" to="/">
            <Text.Normal size="small">
              Home
            </Text.Normal>
          </Link>
          <Link data-testid="about-link" to="/about/">
            <Text.Normal size="small">
              About
            </Text.Normal>
          </Link>
          <Link data-testid="events-link" to="/events/">
            <Text.Normal size="small">
              Calendar
            </Text.Normal>
          </Link>
        </div>
        <div className="footer-links col d-flex flex-column">
          <Link data-testid="news-link" to="/news/">
            <Text.Normal size="small">
              News
            </Text.Normal>
          </Link>
          <Link data-testid="videos-link" to="/videos/">
            <Text.Normal size="small">
              Videos
            </Text.Normal>
          </Link>
        </div>
        <div className="contact col ml-5">
          <Text.Normal size="small">
            <b>Contact us:</b>
          </Text.Normal>
          <Text.Normal size="small">
            emergingartistschicago@gmail.com
          </Text.Normal>
        </div>
      </div>
    </footer>
  )
}

export default Footer
