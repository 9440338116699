/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "../Header"
import Footer from "../Footer"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import TopBanner from "../TopBanner"
import '../Header/styles.scss'
import Helmet from 'react-helmet'
import favicon from '../../images/eac-favicon.png'
import './styles.scss'

const Layout = ({
  children,
  thick,
  location,
  pageContext,
  customCrumb,
  noBreadcrumb,
  banner,
  maxWidth,
  contentTopMargin
}) => {
  const { crumbs } = pageContext ? pageContext.breadcrumb : {}
  const { pathname } = location ? location : {}

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} type="image/png" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin="anonymous" />
        <link href='https://fonts.googleapis.com/css?family=Kumbh+Sans:bold,light,regular' rel='stylesheet' />
      </Helmet>
      <Header />
      {noBreadcrumb ? null : (
        <Breadcrumb
          crumbs={crumbs || []}
          crumbSeparator=" / "
          crumbLabel={customCrumb}
          disableLinks={["/partnumbers", pathname]}
        />
      )}
      <div className="headerSlot"></div>
      {banner && banner.image &&
        <TopBanner
          img={banner.image}
          text={banner.text}
          link={{ label: banner.link_label, to: banner.link_url }}
          copyright={banner.copyright_text}
        />
      }
      <div
        className={`${contentTopMargin ? 'contentTopMargin' : ''}`}
        style={{
          margin: `0 auto`,
          maxWidth: thick ? null : maxWidth ? maxWidth : 1040,
        }}
      >
        <main style={{ minHeight: "70vh" }}>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
