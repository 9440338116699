import React from 'react'
import { Link } from 'gatsby'
import Text from '../Text'
import './styles.scss'

export const TopBanner = ({ img, text, link, copyright }) => {
  if (img) {
    return (
      <div className="banner">
        <img src={img} alt="banner" />
        <div className="bannerText">
          <Text.Header color="white" size="xlarge">{text}</Text.Header>
          {link && link.to && link.label &&
            <button className="green-btn">
              <Text.Normal size="large" weight="bold">
                <Link to={link.to}>{link.label}</Link>
              </Text.Normal>
            </button>
          }
        </div>
        { copyright &&
          <Text.Normal size="small mobile-x-small" className='copyright' color='white'>&copy;{`${copyright}`}</Text.Normal>
        }
      </div>
    )
  } else return null
}

export default TopBanner