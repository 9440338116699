import { Link } from "gatsby"
import React from "react"
import hamburgerIcon from "../../icons/hamburger-icon.svg"
import Text from "../Text"
import "./styles.scss"

const Header = () => (
  <header>
    <div className="navbar navbar-expand-md">
      <div className="navbar-brand">
        <Link to="/">
          <img
            data-testid="eac-logo"
            src="https://res.cloudinary.com/dthskrjhy/image/upload/v1602511555/Emerging%20Artists%20Chicago/logo/EAC_Logo_no_background.png"
            alt="Emerging Artists Chicago Logo"
            width="120"
          />
        </Link>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarLinks"
        aria-controls="navbarLinks"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src={hamburgerIcon} alt="hamburger dropdown" />
      </button>
      <div
        className="collapse navbar-collapse navbar-links text-center"
        id="navbarLinks"
      >
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Text.Normal weight="lighter">
              <Link activeClassName="active" data-testid="about-link" to="/about/">
                About
            </Link>
            </Text.Normal>
          </li>
          <li className="nav-item">
            <Text.Normal weight="lighter">
              <Link activeClassName="active" data-testid="events-link" to="/events/">
                Calendar
            </Link>
            </Text.Normal>
          </li>
          <li className="nav-item">
            <Text.Normal weight="lighter">
              <Link activeClassName="active" data-testid="news-link" to="/news/">
                News
            </Link>
            </Text.Normal>
          </li>
          <li className="nav-item">
            <Text.Normal weight="lighter">
              <Link activeClassName="active" data-testid="videos-link" to="/videos/">
                Videos
            </Link>
            </Text.Normal>
          </li>
        </ul>
      </div>
    </div>
  </header>
)

export default Header
